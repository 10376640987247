import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ItensCarrinhoNovo from "../views/pagamento/ItensCarrinhoNovo";
import ProdutoDetalhe from "../views/produtos/web/ProdutoDetalhe";
import {
  DadosPessoaisCliente,
  DadosPessoaisClienteMobile,
  ErrorApplication,
  FinalizacaoPedido,
  MeusPedidos,
  MeusPedidosMobile,
  PedidoDetalheCliente,
  ProdutosPesquisa,
  ProdutosPorAnuncioOferta,
  ProdutosPorDepartamento,
  ResetarSenha,
  ListaCompraCliente,
  ListaCompraClienteMobile,
  PrincipalMobile,
  Principal,
  LoginAdmin,
  Dashboard,
  InformacoesGerais,
  ConfigColors,
  ListarProdutosCadastrados,
  ConcorrenteCadastrar,
  ConcorrenteListar,
  HorarioEntregas,
  HorarioRetiradas,
  HorarioTaxas,
  AssinaturasListar,
  AssinaturasEditar,
} from "../views";
import {
  Cadastrar,
  ClienteEditar,
  ClientesDashboard,
  DetalhesPedidoListagem,
  GestorOrcamento,
  OrcamentoDashboard,
  ProdutosDashboard,
  ProdutosDetalheDashboard,
} from "../view_dashboard";
import CadastroCliente from "../views/cliente/CadastroCliente";
import CadastrarClienteMobile from "../views/cliente/mobile/CadastrarClienteMobile";
import { Utils } from "../utilities";
import FinalizacaoPedidoMobile from "../views/pedido/FinalizacaoPedidoMobile";
import ProdutosPesquisaMobile from "../views/produtos/ProdutosPesquisaMobile";
import ProdutosPorDepartamentoMobile from "../views/produtos/mobile/ProdutosPorDepartamentoMobile";
import ProdutosPorAnuncioMobile from "../views/produtos/mobile/ProdutosPorAnuncioMobile";
import CadastroAnuncio from "../views/cadastro/CadastroAnuncio";
import AppMobile from "../AppMobile";
import PagamentoProcessado from "../views/pagamento/PagamentoProcessado";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import DepartamentoSecao from "../view_dashboard/DepartamentoSecao/DepartamentoSecao";
import EditarMenu from "../view_dashboard/MenusDashboard/EditarMenu";
import MenuDepartamento from "../view_dashboard/MenusDashboard/MenuDepartamento";
import AppWeb from "../AppWeb";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import AppMobileFc from "../AppMobileFc";
import ProdutosPorDepartamentoNovo from "../views/produtos/web/ProdutosPorDepartamentoNovo";
import ConfigBanner from "../views/personalizacao/ConfigBanner";

const RoutesNew = (props) => {
  const history = useHistory();

  function getRoute(path, component) {
    return (
      <Route
        path={path}
        render={(props) => {
          return Utils.isViewMobile() ? (
            <AppMobileFc {...props}>{component} </AppMobileFc>
          ) : (
            <AppWeb {...props}>{component}</AppWeb>
          );
        }}
      />
    );
  }

  return (
    <Switch>
      <Redirect from="/admin/horarios" exact to="/admin/horarios/entregas" />
      <Redirect
        from="/admin/personalizacao"
        exact
        to="/admin/personalizacao/geral"
      />
      <Redirect from="/admin/pesquisas" exact to="/admin/pesquisas/listar" />
      <Redirect from="/admin" exact to="/admin/dashboard" />

      <PublicRoute
        restricted={true}
        component={LoginAdmin}
        path="/admin/login"
        exact
      />

      <PrivateRoute Component={Dashboard} path="/admin/dashboard" exact />

      <PrivateRoute
        Component={HorarioEntregas}
        path="/admin/horarios/entregas"
        exact
      />
      <PrivateRoute
        Component={HorarioRetiradas}
        path="/admin/horarios/retiradas"
        exact
      />
      <PrivateRoute
        Component={HorarioTaxas}
        path="/admin/horarios/taxas"
        exact
      />

      <PrivateRoute Component={ConfigBanner} path="/admin/banners" exact />

      <PrivateRoute
        Component={InformacoesGerais}
        path="/admin/personalizacao/geral"
        exact
      />
      <PrivateRoute
        Component={ConfigColors}
        path="/admin/personalizacao/cores"
        exact
      />

      <PrivateRoute
        Component={ListarProdutosCadastrados}
        path="/admin/pesquisas/listar"
        exact
        history={history}
      />
      <PrivateRoute
        Component={ConcorrenteListar}
        path="/admin/pesquisas/concorrentes"
        exact
      />
      <PrivateRoute
        Component={ConcorrenteCadastrar}
        path="/admin/pesquisas/concorrentes/cadastrar"
        exact
      />

      <PrivateRoute
        Component={AssinaturasListar}
        path="/admin/assinaturas"
        exact
      />
      <PrivateRoute
        Component={AssinaturasEditar}
        path="/admin/assinaturas/editar"
        exact
      />

      <PrivateRoute
        Component={DepartamentoSecao}
        path="/admin/departamento"
        exact
      />

      <PrivateRoute Component={EditarMenu} path="/admin/menus/:menu" exact />
      <PrivateRoute Component={MenuDepartamento} path="/admin/menus" exact />
      <PrivateRoute Component={MenuDepartamento} path="/admin/menus" exact />

      <PrivateRoute
        Component={ProdutosDashboard}
        path="/admin/produtos"
        exact
      />
      <PrivateRoute
        Component={ProdutosDetalheDashboard}
        path="/admin/produtos/:id"
        exact
      />
      <PrivateRoute
        Component={Cadastrar}
        path="/admin/produtos/cadastrar"
        exact
      />

      <PrivateRoute
        Component={ClientesDashboard}
        path="/admin/clientes"
        exact
      />
      <PrivateRoute
        Component={ClienteEditar}
        path="/admin/clientes/editar"
        exact
      />

      <PrivateRoute
        Component={OrcamentoDashboard}
        path="/admin/pedidos/"
        exact
      />
      <PrivateRoute
        Component={GestorOrcamento}
        path="/admin/pedidos/gestor"
        exact
      />
      <PrivateRoute
        Component={DetalhesPedidoListagem}
        path="/admin/pedidos/:idpedido"
        exact
      />

      {/* <PrivateRoute Component={ListaCompraListar} path="/admin/listacompras/" exact />
      <PrivateRoute Component={ListaCompraDashboard} path="/admin/listacompras/editar" exact /> */}

      {getRoute(
        "/anuncio/cadastro/admin",
        <CadastroAnuncio showHeader={true} showRodape={true} {...props} />
      )}
      {getRoute(
        "/carrinho",
        Utils.isViewMobile() ? (
          <ItensCarrinhoNovo
            mobile={true}
            showRodape={false}
            showHeader={false}
            {...props}
          />
        ) : (
          <ItensCarrinhoNovo
            mobile={false}
            showRodape={false}
            showHeader={false}
            {...props}
          />
        )
      )}
      {/* {getRoute(
        "/carrinho2",
        Utils.isViewMobile() ? (
          <ItensCarrinhoMobile
          
            showRodape={false}
            showHeader={false}
          />
        ) : (
          <ItensCarrinho
          
            showRodape={false}
            showHeader={false}
          />
        )
      )} */}
      {getRoute(
        "/anuncio/produtos",
        Utils.isViewMobile() ? (
          <ProdutosPorAnuncioMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPorAnuncioOferta
            showHeader={true}
            showRodape={false}
            {...props}
          />
        )
      )}
      {getRoute(
        "/produto/departamento/:descricao",
        Utils.isViewMobile() ? (
          <ProdutosPorDepartamentoMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPorDepartamento
            showHeader={true}
            showRodape={false}
            showDepart={true}
            {...props}
          />
        )
      )}

      {getRoute(
        "/produtos/departamento/:descricao",
        Utils.isViewMobile() ? (
          <ProdutosPorDepartamentoMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPorDepartamentoNovo
            showHeader={true}
            showRodape={false}
            showDepart={true}
            {...props}
          />
        )
      )}
      {getRoute(
        "/produto/secao/:descricao",
        Utils.isViewMobile() ? (
          <ProdutosPorDepartamentoMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPorDepartamento
            showHeader={true}
            showRodape={false}
            {...props}
          />
        )
      )}
      {getRoute(
        "/produto/marca/:descricao",
        Utils.isViewMobile() ? (
          <ProdutosPorDepartamentoMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPorDepartamento
            showHeader={true}
            showRodape={false}
            {...props}
          />
        )
      )}
      {getRoute(
        "/produto/ean/:codigobarras",
        Utils.isViewMobile() ? (
          <ProdutosPesquisaMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPesquisa
            showHeader={true}
            showRodape={false}
            showDepart={true}
            {...props}
          />
        )
      )}

      {getRoute(
        "/produto/:codigoBarras",
        <ProdutoDetalhe showHeader={true} showRodape={true} {...props} />
      )}

      {getRoute(
        "/pedido/cielo/confirmarpagamento/debito",
        <PagamentoProcessado showHeader={false} showRodape={false} {...props} />
      )}

      {getRoute(
        "/resultadopesquisa",
        Utils.isViewMobile() ? (
          <ProdutosPesquisaMobile
            showHeader={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <ProdutosPesquisa showHeader={true} showRodape={false} {...props} />
        )
      )}

      {getRoute(
        "/finalizacao",
        Utils.isViewMobile() ? (
          <FinalizacaoPedidoMobile
            showHeader={false}
            showRodape={false}
            {...props}
          />
        ) : (
          <FinalizacaoPedido showHeader={false} showRodape={false} {...props} />
        )
      )}
      {getRoute(
        "/cadastrarcliente",
        Utils.isViewMobile() ? (
          <CadastrarClienteMobile
            showHeader={false}
            showRodape={false}
            {...props}
          />
        ) : (
          <CadastroCliente showHeader={false} showRodape={false} {...props} />
        )
      )}
      {getRoute(
        "/cliente/pedidos/detalhe",
        Utils.isViewMobile() ? (
          <PedidoDetalheCliente
            mobile={true}
            showHeader={true}
            showDepart={true}
            showRodape={false}
            {...props}
          />
        ) : (
          <PedidoDetalheCliente
            mobile={false}
            showHeader={true}
            showDepart={true}
            showRodape={false}
            {...props}
          />
        )
      )}
      {getRoute(
        "/cliente/pedidos",
        Utils.isViewMobile() ? (
          <MeusPedidosMobile showHeader={true} showRodape={false} {...props} />
        ) : (
          <MeusPedidos
            showHeader={true}
            showDepart={true}
            showRodape={false}
            {...props}
          />
        )
      )}
      {getRoute(
        "/cliente/listascompras",
        Utils.isViewMobile() ? (
          <ListaCompraClienteMobile
            showHeader={false}
            showRodape={false}
            {...props}
          />
        ) : (
          <ListaCompraCliente
            showHeader={true}
            showRodape={false}
            showDepart={true}
            {...props}
          />
        )
      )}
      {getRoute(
        "/cliente",
        Utils.isViewMobile() ? (
          <DadosPessoaisClienteMobile
            showHeader={false}
            showRodape={false}
            {...props}
          />
        ) : (
          <DadosPessoaisCliente
            showHeader={true}
            showDepart={true}
            showRodape={false}
            {...props}
          />
        )
      )}
      {getRoute(
        ["/resetarsenha", "/resetarsenha/:id"],
        <ResetarSenha showHeader={false} showRodape={false} {...props} />
      )}
      {getRoute(
        "/",
        Utils.isViewMobile() ? (
          <PrincipalMobile showHeader={true} showDepart={true} {...props} />
        ) : (
          <Principal showHeader={true} showDepart={true} {...props} />
        )
      )}
      {/* {getRoute(
        "/*",
        Utils.isViewMobile() ? (
          <PrincipalMobile showHeader={true} showDepart={true} {...props} />
        ) : (
          <Principal showHeader={true} showDepart={true} {...props} />
        )
      )} */}
      {getRoute("/error", <ErrorApplication />)}
    </Switch>
  );
};

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
  };
};

export default connect(mapStateToProps)(withRouter(RoutesNew));
